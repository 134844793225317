export function shortScripts () {
    //move social media to badge

    const footerSocialMedia = document.querySelector('.footerSocialMedia');
    const footerBadge = document.querySelector('.footerBadge');
    if(footerSocialMedia) {
        const originalParent = footerSocialMedia.parentNode;
        const originalNextSibling = footerSocialMedia.nextElementSibling;

        if (window.innerWidth <= 1249) {
            if (footerSocialMedia && footerBadge && !footerBadge.querySelector('.footerSocialMedia')) {
                footerBadge.appendChild(footerSocialMedia.cloneNode(true)); // Klonen und Einfügen
            }
        } else {
            const footerSocialMediaClone = footerBadge.querySelector('.footerSocialMedia');
            if (footerSocialMediaClone) {
                footerSocialMediaClone.remove();
            }
            if (originalParent && !originalParent.contains(footerSocialMedia)) {
                if (originalNextSibling) {
                    originalParent.insertBefore(footerSocialMedia, originalNextSibling); // Rückgabe an Originalposition
                } else {
                    originalParent.appendChild(footerSocialMedia);
                }
            }
        }
    }


    //Link fake on play-page
    let linkBoxes = document.querySelectorAll(".js-linkBox");
    if (linkBoxes) {
        for (let linkBox of linkBoxes) {
            let linkBoxLink = linkBox.dataset.href;
            linkBox.addEventListener("click", () => {
                window.location.href = linkBoxLink;
            });
        }
    }
}