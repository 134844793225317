export function shoppingSwitch (query = document.querySelectorAll('.switchItemsWrapper')) {
    for (let switcher of query) {
        for (const child of switcher.children) {
            child.addEventListener("click", function() {
                for (const childToggle of switcher.children) {
                    childToggle.classList.remove('activeSwitchItem');
                }
                let packagea = this.dataset.package;
                let images = document.querySelectorAll('.switchImageWrapper');
                for(let image of images) {
                    if(image.dataset.image != packagea) {
                        image.style.display = 'none';
                    } else {
                        image.style.display = 'block';
                    }
                }
                this.classList.add('activeSwitchItem');
            });
        }
    }
}